import Dinero from 'dinero.js';
import { RootState } from '../app/store';
import { DUMMY_ITEM, KEY, POS_ID, POS_NAME } from '../constants';
import { CartItemAdditionTypes } from '../constants/event';
import {
  ModalityType,
  PosProperties,
  PropertyType,
} from '../generated-interfaces/graphql';
import { ISendItemProperties } from '../reducers/orderSlice.props';
import { GenericObjectType } from '../types';
import { findObjectInObjectByKeyValue } from '../utils';
import { POS_BOOLEAN_PROPERTIES_LIST } from '../utils/constants';
import inMemoryGroupIdMapping from '../utils/inMemoryGroupIdMapping';
import logger from '../utils/logger';
import {
  ParsedMenuItem,
  ParsedModifierGroup,
  convertToMap,
  getMenuItemPrice,
  sortChildrenModGroup,
  checkItemInTree,
  MenuResponses,
} from './menu';
import { computeCartSubtotal, computeCartTax } from './money';
import { GenericMap } from './types';
import { ModSymbolCodeNameMappingType } from './mappings';
import { getMenuUnificationFeatureFlag } from './session-storage';

export interface CartItem extends ParsedMenuItem {
  cartItemId: number;
  modality: ModalityType;
  parentCartModifierGroupId?: string;
  childModifierGroups: GenericMap<CartModifierGroup>;
  // itemOrder?: number;
  itemLevelMemo?: string;
  modcode?: string;
  addedBy?: CartItemAdditionTypes;
  isUpsell?: boolean;
  isUpsellPrompt?: boolean;
  label?: string;
  autoCombinedCartItemIds?: number[];
}

export interface CartModifierGroup extends ParsedModifierGroup {
  cartModifierGroupId: string;
  menuModifierGroupId: string;
  name: string;
  selectedItems: GenericMap<CartItem>;
}

export interface CartTotal {
  subtotal: number;
  tax: number;
  total: number;
}

export function isValidCartSelector(state: RootState): boolean {
  const cartItems = state.cart.cartItems;
  return !getAllInvalidModGroups(cartItems).length;
}

export function activeCartSelector(state: RootState): boolean {
  return Object.keys(state.cart.cartItems).length > 0;
}

export function cartTotalSelector(state: RootState): CartTotal {
  const { restaurantSettings } = state.restaurant.selectedRestaurantDetails;
  const modality = state.cart.modality;
  const cartItems = Object.values(state.cart.cartItems);
  const cartItemsQuantity = state.cart.cartItemsQuantity;
  const menuItems = Object.values(state.menu.fullMenuItems).reduce(
    (acc, menuItem) => {
      if (!menuItem.isModifierOnly) {
        acc[menuItem.itemId] = menuItem;
      }
      return acc;
    },
    {} as GenericMap<ParsedMenuItem>
  );
  const subtotal = computeCartSubtotal(cartItems, modality, cartItemsQuantity);
  const tax = computeCartTax(
    cartItems,
    menuItems,
    modality,
    restaurantSettings,
    cartItemsQuantity
  );
  const total = subtotal.add(tax);
  return {
    subtotal: subtotal.getAmount(),
    tax: tax.getAmount(),
    total: total.getAmount(),
  };
}

export const calculateItemTotal = (
  cartItem: CartItem,
  modality: ModalityType
): Dinero.Dinero => {
  let basePrice = Dinero({ amount: getMenuItemPrice(cartItem, modality) });
  Object.values(cartItem.childModifierGroups).forEach((modGroup) => {
    Object.values(modGroup.selectedItems).forEach(
      (item) => (basePrice = basePrice.add(calculateItemTotal(item, modality)))
    );
  });
  return basePrice;
};

const isInvalidModGroup = (
  modGroup: ParsedModifierGroup,
  cartModGroup: CartModifierGroup | undefined
): boolean => {
  // If they need to select at least one but no items have been selected
  if (modGroup.minimumSelections > 0 && !cartModGroup) {
    return true;
  }
  // If they don't need to select any and we didn't pass it in, it's fine
  if (!cartModGroup) {
    return false;
  }

  if (modGroup.minimumSelections === 0 && modGroup.maximumSelections === 0) {
    return false;
  }

  const selectedItemsSize = Object.keys(cartModGroup.selectedItems).length;
  if (
    selectedItemsSize < modGroup.minimumSelections ||
    (selectedItemsSize > modGroup.maximumSelections &&
      modGroup.maximumSelections !== -1)
  ) {
    return true;
  }
  return false;
};

const getInvalidModGroups = (
  modifierGroups: GenericMap<ParsedModifierGroup>,
  childModifierGroups: GenericMap<CartModifierGroup>
): ParsedModifierGroup[] => {
  return Object.values(modifierGroups).filter((modGroup) => {
    const cartModGroup: CartModifierGroup | undefined =
      childModifierGroups[modGroup.id];
    return isInvalidModGroup(modGroup, cartModGroup);
  });
};

export const flatInvalidModGroups = (
  cartItem: CartItem
): ParsedModifierGroup[] => {
  let invalidModGroups: ParsedModifierGroup[] = [];

  invalidModGroups.push(
    ...getInvalidModGroups(
      cartItem.modifierGroups,
      cartItem.childModifierGroups
    )
  );

  if (!getMenuUnificationFeatureFlag()) {
    const sortOrderMap = convertToMap(cartItem.sortOrder);

    invalidModGroups.sort((a, b) => {
      const sortA = sortOrderMap[a.id].sortOrder;
      const sortB = sortOrderMap[b.id].sortOrder;
      if (sortA == null || sortB == null) {
        return 0;
      }
      return sortA - sortB;
    });
  }

  Object.values(cartItem.childModifierGroups).forEach((chidModifierGroup) => {
    Object.values(chidModifierGroup.selectedItems).forEach((selectItem) => {
      invalidModGroups.push(...flatInvalidModGroups(selectItem));
    });
  });

  return invalidModGroups;
};

export const getAllInvalidModGroups = (
  cartItems: GenericMap<CartItem>
): ParsedModifierGroup[] => {
  let invalidModGroups: ParsedModifierGroup[] = [];
  Object.values(cartItems).forEach((cartItem) => {
    invalidModGroups.push(...flatInvalidModGroups(cartItem));
  });
  return invalidModGroups;
};

export const getCartModGroup = (
  modGroup: ParsedModifierGroup,
  currentCartItem: CartItem
) => {
  let node: CartItem;
  node = currentCartItem;

  return node.modifierGroups[modGroup.id]
    ? node.childModifierGroups[modGroup.id]
    : null;
};

/**
 * For the given cart item, loop through its child modifier groups and its selected items to find the matching mod group recursively
 * @param item Cart item in which the search has to be performed
 * @param modGroupId Find for this modgroup
 * @param selectedItemNodes This is an array into which the selectedItemNode will be populated when the condition matches
 */
export const recursivelyFindSelectedItemNode = (
  item: CartItem,
  modGroupId: string,
  selectedItemNodes: CartItem[],
  rootModGroupId?: string
) => {
  let childModifierGroups = item.childModifierGroups;
  if (rootModGroupId) {
    childModifierGroups = convertToMap(
      Object.values(item.childModifierGroups).filter(
        (childModGroup) => childModGroup.id === rootModGroupId
      )
    );
  }
  Object.values(childModifierGroups).every((childItemEntry) => {
    const { selectedItems } = childItemEntry || {};

    Object.values(selectedItems).every((selectedItem) => {
      const { modifierGroups } = selectedItem;
      const matchedModGroup = Object.values(modifierGroups).filter(
        (modifierGroup) => modifierGroup.id === modGroupId
      );

      if (matchedModGroup.length) {
        selectedItemNodes.push(selectedItem);
        return false;
      } else {
        recursivelyFindSelectedItemNode(
          selectedItem,
          modGroupId,
          selectedItemNodes
        );
        return true;
      }
    });

    return !selectedItemNodes.length;
  });
};

export const getCartModGroupInChildModifierGroups = (
  modGroup: ParsedModifierGroup,
  currentCartItem: CartItem
) => {
  let node: CartItem;
  node = currentCartItem;

  if (Object.keys(node.modifierGroups).includes(modGroup.id)) {
    return node.childModifierGroups[modGroup.id];
  } else {
    const selectedItemNodes: CartItem[] = [];
    recursivelyFindSelectedItemNode(node, modGroup.id, selectedItemNodes);
    if (selectedItemNodes.length) {
      return selectedItemNodes[0].childModifierGroups?.[modGroup.id];
    }
    return null;
  }
};

export const getCartInvalidModGroupDescriptions = (
  invalidModGroups: ParsedModifierGroup[]
) => {
  let invalidModGroupDescriptions: string[] = [];
  if (invalidModGroups.length > 0) {
    invalidModGroups.forEach((modGroup) => {
      if (modGroup.description) {
        invalidModGroupDescriptions.push(modGroup.description);
      } else {
        invalidModGroupDescriptions.push('.');
      }
    });
  }
  return invalidModGroupDescriptions;
};

let pathToGroupId: string = '';

export const getCartItemProperties = (
  cartItem: CartItem,
  groupId: string[],
  path_to_entity: string[],
  restaurantCode: string,
  itemsQuantity: Record<string, number>,
  isModifier = false
) => {
  const groupIdMappings = inMemoryGroupIdMapping.getGroupIdMapping(
    restaurantCode || ''
  );

  const boolList = ['true', 'false', ''];
  let posNameAdded = false;
  const posProperties = Object.values(cartItem.posProperties).reduce(
    (acc, { key, value }) => {
      if (key === POS_ID && value !== DUMMY_ITEM) {
        groupId.push(value);
      }
      if (key === POS_NAME && !posNameAdded) {
        path_to_entity.push(value);
        posNameAdded = true;
      }
      acc[key] =
        POS_BOOLEAN_PROPERTIES_LIST.includes(key) &&
        boolList.includes(value.toLowerCase())
          ? value.toLowerCase() === boolList[0]
          : value;
      return acc;
    },
    {} as { [key: string]: any }
  );

  if (cartItem.modcode) {
    posProperties.modcode = cartItem.modcode;
  }

  pathToGroupId = groupId.slice(0, -1).join('__');
  if (groupIdMappings[pathToGroupId]) {
    posProperties['group_id'] = groupIdMappings[pathToGroupId];
    posProperties['component_id'] = groupIdMappings[pathToGroupId];
    pathToGroupId = '';
  } else {
    logger.error({
      restaurantCode,
      message: `Unable to find component id for ${pathToGroupId}`,
    });
  }
  const posIDObject: GenericObjectType = findObjectInObjectByKeyValue(
    cartItem.posProperties,
    KEY,
    POS_ID
  );
  const childItems = sortChildrenModGroup(cartItem).reduce((acc, modGroup) => {
    const mod_group_path_to_entity = [...path_to_entity];
    const posNamePosProperty = Object.values(
      modGroup?.posProperties || {}
    ).find(({ key }) => key === POS_NAME);
    if (posNamePosProperty) {
      mod_group_path_to_entity.push(posNamePosProperty.value);
    }
    if (posIDObject?.value !== DUMMY_ITEM) groupId.push(modGroup.prpName);
    const selectedItems = Object.values(modGroup.selectedItems).map((item) => {
      const cartItem = getCartItemProperties(
        item,
        [...groupId],
        [...mod_group_path_to_entity],
        restaurantCode,
        itemsQuantity,
        true
      );
      return cartItem;
    });
    groupId.pop();
    acc.push(...selectedItems);
    return acc;
  }, [] as ISendItemProperties[]);

  const { isUpsell, isUpsellPrompt, label } = cartItem;

  const itemData: ISendItemProperties = {
    id: parseInt(cartItem.id),
    name: cartItem.name,
    memo: cartItem.itemLevelMemo || undefined,
    quantity: isModifier ? 1 : itemsQuantity[cartItem.cartItemId] || 1,
    price: (getMenuItemPrice(cartItem, cartItem.modality) / 100).toFixed(2), // Apply price override
    options: childItems,
    children: childItems,
    pos_specific_properties: posProperties,
    added_by: cartItem.addedBy || CartItemAdditionTypes.human,
    path_to_entity,
    ...(isUpsell !== undefined && { is_upsell: isUpsell }),
    ...(isUpsellPrompt !== undefined && { is_upsell_prompt: isUpsellPrompt }),
    ...(label && { label }),
  };
  return itemData;
};

export const selectModifierInCartItem = (
  cartItem: CartItem,
  menuItemId: string,
  modGroupId: string,
  selected: boolean,
  modCode: string,
  modSymbolMapping: ModSymbolCodeNameMappingType,
  posSettings: GenericMap<PosProperties>,
  modality: ModalityType,
  pathToItem?: string,
  rootModGroupId?: string
) => {
  let node = cartItem;
  let sequenceId = cartItem.cartItemId;
  const selectedItemNodes: CartItem[] = [];
  if (pathToItem) {
    node =
      checkItemInTree({
        cartItem: node,
        pathToItem,
        fromSelectModifier: true,
      }) || node;
  } else {
    recursivelyFindSelectedItemNode(
      cartItem,
      modGroupId,
      selectedItemNodes,
      rootModGroupId
    );
  }
  if (selectedItemNodes.length) {
    node = selectedItemNodes[0];
  }
  if (!node) {
    return;
  }

  const modGroup = node.modifierGroups[modGroupId];
  const menuItem = modGroup.menuItems[menuItemId];
  if (!modGroup || !menuItem) {
    return;
  }
  if (!node.childModifierGroups[modGroupId]) {
    node.childModifierGroups[modGroupId] = {
      ...node.modifierGroups[modGroupId],
      cartModifierGroupId: String(sequenceId++),
      menuModifierGroupId: modGroupId,
      name: modGroup.name,
      selectedItems: {},
      posProperties: convertToMap(
        Object.values(posSettings).filter(
          (setting) =>
            setting.objectPrimaryKey === modGroupId &&
            setting.propertyType === PropertyType.ModifierGroup
        )
      ),
    };
  }

  const existing = node.childModifierGroups[modGroupId].selectedItems;
  const menuItemIsAlreadySelected = existing && existing[menuItemId];

  if (modGroup.maximumSelections === 1) {
    // Remove other choices automatically
    // Need to remove other choices when select different choice
    if (!menuItemIsAlreadySelected) {
      node.childModifierGroups[modGroup.id].selectedItems = {};
    }
  } else if (
    selected &&
    existing &&
    modGroup.maximumSelections === Object.keys(existing).length
  ) {
    // Do not allow selection of more then maximumSelections
    return;
  }

  if (!selected) {
    delete node.childModifierGroups[modGroup.id].selectedItems[menuItem.itemId];
  } else {
    // Select a new modifier or update the modcode
    const mappedModCode = modSymbolMapping[modCode]?.code || '';
    node.childModifierGroups[modGroup.id].selectedItems[menuItem.itemId] = {
      ...menuItem,
      modality,
      cartItemId: sequenceId++,
      childModifierGroups: {},
      modcode: mappedModCode,
    };
  }
};

export const processDefaultSelectedModifiers = (
  cartItem: CartItem,
  modSymbolMapping: ModSymbolCodeNameMappingType,
  posSettings: MenuResponses['posSettings'],
  modality: ModalityType
) => {
  // Loop through all modifier groups in the cart item and select the default items if they exist
  Object.values(cartItem.modifierGroups).forEach((modGroup) => {
    if (
      modGroup.defaultSelectedItemIds &&
      modGroup.defaultSelectedItemIds.length
    ) {
      modGroup.defaultSelectedItemIds.forEach((itemId) => {
        try {
          logger.log(
            `Select default item ${itemId} in group ${modGroup.id} for cartItem ${cartItem.name}`
          );
          selectModifierInCartItem(
            cartItem,
            itemId.toString(),
            modGroup.id,
            true,
            '',
            modSymbolMapping,
            posSettings,
            modality
          );
        } catch (err) {
          logger.error('Error selecting default item', err);
        }

        // If there are child items, recursively process their default selected modifiers
        const childModGroups = Object.values(cartItem.childModifierGroups);
        if (childModGroups.length) {
          childModGroups.forEach((childModGroup) => {
            const selectedItems = Object.values(childModGroup.selectedItems);
            if (selectedItems.length) {
              selectedItems.forEach((item) =>
                processDefaultSelectedModifiers(
                  item,
                  modSymbolMapping,
                  posSettings,
                  modality
                )
              );
            }
          });
        }
      });
    }
  });
};

export const getPosPropertyValue = (
  posPropertiesRecord: Record<string, PosProperties>,
  posPropKey: string
) => {
  return Object.values(posPropertiesRecord).find(
    (posProp) => posProp.key === posPropKey
  )?.value;
};

export const getCartItemNonDummyNode = (cartItem: CartItem) => {
  const cartItemPosId = getPosPropertyValue(cartItem.posProperties, POS_ID);
  let node = cartItem;

  const childModifierGroups = Object.values(cartItem.childModifierGroups);
  if (
    cartItemPosId === DUMMY_ITEM &&
    childModifierGroups.length &&
    Object.values(childModifierGroups[0].selectedItems).length
  ) {
    node = Object.values(childModifierGroups[0].selectedItems)[0];
    logger.log(`Move the node to non dummy item ${node.name} in the cart tree`);
  }
  return node;
};

export const getValidCartItems = (cartItems: Record<string, CartItem>) => {
  return Object.values(cartItems)
    .filter((cartItem) => flatInvalidModGroups(cartItem).length === 0)
    .reduce((acc, entry) => {
      acc[entry.cartItemId] = entry;
      return acc;
    }, {} as GenericMap<CartItem>);
};

export const getAutoCombinedCartItemIdsCount = (
  autoCombinedCartItemIds: number[]
) => {
  return autoCombinedCartItemIds.reduce((acc, currentValue) => {
    const currentValueToString = currentValue.toString();
    if (Object.keys(acc).includes(currentValueToString)) {
      acc[currentValueToString] += 1;
    } else {
      acc[currentValueToString] = 1;
    }
    return acc;
  }, {} as Record<string, number>);
};
