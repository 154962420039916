import { FormControl, FormGroup, FormLabel, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { memo } from 'react';
import { CartModifierGroup } from '../../../utils/cart';
import { checkItemInTree, isItem86edToday } from '../../../utils/menu';
import { ModGroupLabel } from '../components/ModGroupLabel';
import MultipleModChoice from '../components/MultipleModChoice';
import RecursiveModGroup from '../components/RecursiveModGroup';
import { getModGroupItemLabel, sortAndFilterMenuItems } from '../util/helper';
import { IModGroup } from './modGroup.props';
import { hasModGroupChildren } from './modGroup.utils';
import { getMenuUnificationFeatureFlag } from '../../../utils/session-storage';

const useStyles = makeStyles<{ hasChildren: boolean }>()(
  ({ spacing }, { hasChildren }) => {
    const mBottom = hasChildren ? spacing(1.25) : 0;
    return {
      modGroup: {
        margin: '0 !important',
        '& legend': {
          width: '100%',
        },
        width: '100%',
      },
      modGroupContainer: {
        marginBottom: mBottom,
      },
    };
  }
);

const MultipleSelectionModGroup = (props: IModGroup) => {
  const { modGroup, onChange, cartItem, pathToItem } = props;

  //sort and filter the modifiers before rendering
  const menuItems = sortAndFilterMenuItems(modGroup.menuItems);

  const currentItem = checkItemInTree({
    cartItem,
    pathToItem,
  }) as CartModifierGroup;
  let selectedModNumber = 0;
  if (currentItem) {
    const selectedMod = currentItem?.selectedItems || [];
    selectedModNumber = Object.values(selectedMod).length;
  }
  const key = [cartItem.id, modGroup.id].join('_');
  const hasChildren = hasModGroupChildren(props);
  const { classes } = useStyles({ hasChildren });

  return (
    <div key={key} className={classes.modGroupContainer}>
      <FormControl component="fieldset" className={classes.modGroup}>
        <FormLabel component="legend">
          <ModGroupLabel
            modGroup={modGroup}
            selectedModNumber={selectedModNumber}
          />
        </FormLabel>
        <FormGroup row>
          {menuItems.map((child) => {
            const is86edToday = isItem86edToday(child);
            const pathToModChoice = [pathToItem, child.id].join('__');
            const isSelected = !!checkItemInTree({
              cartItem,
              pathToItem: pathToModChoice,
            });
            const id = child.itemId;
            const name = child.name;
            const modLabel = getModGroupItemLabel(child);
            if (child.available || is86edToday) {
              return (
                <MultipleModChoice
                  key={pathToModChoice}
                  id={id}
                  name={name}
                  modLabel={modLabel}
                  onChange={onChange(modGroup)}
                  is86edToday={is86edToday}
                  isSelected={isSelected}
                />
              );
            }
            return null;
          })}
        </FormGroup>
      </FormControl>
      <RecursiveModGroup {...props} />
    </div>
  );
};

export default memo(MultipleSelectionModGroup);
