import { ModalityType } from '../../../generated-interfaces/graphql';
import {
  checkForUnavailableRequiredModifierGroup,
  getMenuItemPrice,
  ParsedMenuItem,
} from '../../../utils/menu';
import { printCurrency } from '../../../utils/money';
import { GenericMap } from '../../../utils/types';
import { getMenuUnificationFeatureFlag } from '../../../utils/session-storage';

const novowelWithoutStitute = (str: string) =>
  str.length > 28 ? str.replace(/[aeiou]/gi, '') : str.replace('stitute', '');

export const getModGroupItemLabel = (
  item: ParsedMenuItem,
  modalityState?: ModalityType
) => {
  const price = getMenuItemPrice(item, modalityState);
  let result = `${novowelWithoutStitute(item.name)} `;
  if (price) {
    result += `(${printCurrency(price, 'USD')})`;
  }
  return result;
};

export const sortAndFilterMenuItems = (
  menuItems: GenericMap<ParsedMenuItem>
) => {
  if (getMenuUnificationFeatureFlag()) {
    return Object.values(menuItems);
  }
  return Object.values(menuItems)
    .sort((a, b) => {
      if (
        a.sortOrderByModifierGroup !== null &&
        b.sortOrderByModifierGroup !== null
      ) {
        return a.sortOrderByModifierGroup - b.sortOrderByModifierGroup;
      } else if (a.sortOrderByModifierGroup !== null) {
        return -1;
      } else if (b.sortOrderByModifierGroup !== null) {
        return 1;
      }
      return 0;
    })
    .filter(
      (menuItem) =>
        !checkForUnavailableRequiredModifierGroup(menuItem.modifierGroups)
    );
};
