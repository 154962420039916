import { ParsedMenuItem } from './menu';
import { Category, PropertyType } from '../generated-interfaces/graphql';
import brandMenuData from '../brandMenuData/carlsjr_brand_menu_v2_schema.json';
import { GenericMap } from './types';
import {
  IBrandMenu,
  IConstraint,
  IMUItem,
  IOrderConstraint,
  IOrderItem,
} from '../types/brandMenu';
import { CartItem, CartModifierGroup } from './cart';

export function convertToMapUsingName<T extends { name: string }>(
  entries: T[]
): GenericMap<T> {
  return entries.reduce((acc, entry) => {
    acc[entry.name] = entry;
    return acc;
  }, {} as GenericMap<T>);
}

export const convertToBrandMenuCategories = (
  categories: Category[],
  brandMenu: IBrandMenu
) => {
  const brandMenuItems: string[] = [];
  brandMenu.items.forEach((item: IMUItem) => brandMenuItems.push(item.name));
  return [
    { ...categories[0], name: 'Support Brand Menu', menuItems: brandMenuItems },
  ];
};

export const getMenuModuleConstraints = (
  children: CartModifierGroup,
  cartItemsQuantity: Record<string, number>
) => {
  const selectedItems = Object.values(children.selectedItems).reduce(
    (acc, item) => {
      acc.push(convertToMenuModuleOrder(item, cartItemsQuantity, true));
      return acc;
    },
    [] as IOrderItem[]
  );
  return {
    constraint_name: children.name,
    items: selectedItems as IOrderItem[],
  } as IOrderConstraint;
};

export const convertToMenuModuleOrder = (
  cartItem: CartItem,
  cartItemsQuantity: Record<string, number>,
  isModifier: boolean = false
) =>
  ({
    item: cartItem.name,
    quantity: isModifier ? 1 : cartItemsQuantity[cartItem.cartItemId] || 1,
    constraints:
      Object.values(cartItem.childModifierGroups).length > 0
        ? Object.values(cartItem.childModifierGroups).reduce((acc, child) => {
            acc.push(getMenuModuleConstraints(child, cartItemsQuantity));
            return acc;
          }, [] as IOrderConstraint[])
        : undefined,
  } as IOrderItem);

export const DUMMY_MENU_ITEM: ParsedMenuItem = {
  id: '1594877',
  name: 'dummyItem',
  price: 0,
  tax: null,
  description: '',
  imageUrl: '/no_menu_image.jpg',
  available: true,
  unavailableUntil: null,
  isModifierOnly: false,
  customRequestAvailable: false,
  modifierGroups: {},
  menuOverrides: [],
  menuItemSettings: {
    '18501': {
      id: '18501',
      key: 'IS_TO_GO_ENABLED',
      value: 'false',
      menuItemId: 1594877,
    },
    '18502': {
      id: '18502',
      key: 'IS_DINE_IN_ENABLED',
      value: 'true',
      menuItemId: 1594877,
    },
    '18503': {
      id: '18503',
      key: 'IS_DELIVERY_ENABLED',
      value: 'false',
      menuItemId: 1594877,
    },
  },
  parentCategoryIds: [24877],
  posProperties: {
    '18504': {
      id: '18504',
      propertyType: PropertyType.MenuItem,
      objectPrimaryKey: '1594877',
      key: 'pos_id',
      value: '11014',
    },
  },
  sortOrder: [],
  availableLimitedQuantity: null,
  category: 'Sides',
  categoryId: '24877',
  itemId: '1594877-24877',
  originalMenuItemId: '1594877',
  containsOwnModifierGroup: false,
  sortOrderByCategory: 8,
  sortOrderByModifierGroup: null,
  overrides: {},
};

export const DUMMY_MODIFIER_GROUP = {
  id: '462595',
  name: 'Select Small Drink',
  description: null,
  minimumSelections: 1,
  maximumSelections: 1,
  defaultSelectedItemIds: [],
  menuItems: {},
  posProperties: [],
  sortOrder: [
    {
      id: 9576208,
      sortOrder: 1,
    },
  ],
  voiceProperties: {},
  prpName: 'Select Small Drink',
};

export const processBrandMenu = (brandMenu: IBrandMenu) => {
  const topLevelMenuItems = brandMenu.items
    .filter((item) => item.is_base_item)
    .map((item) => ({
      category: item.category || 'Brand Menu',
      categoryId: item.category || 'Brand Menu',
      id: item.name,
      name: item.name,
      synonyms: item.customer_synonyms || item.raw_menu_synonyms || '',
      available: true,
      unavailableUntil: '',
    }));
  const processedMenu = {
    topLevelMenuItems,
    processedCategoryAndTimePeriodJSON: {},
    processedMenuJSON: {},
    processedModGroupsJSON: {},
    categoriesWithTimePeriod: [],
    availableCategoryWithTimePeriod: [],
    alwaysAvailableCategories: [],
    persistentVoiceProps: {},
    codeNameMapping: {},
    modSymbolMapping: {},
    unavailableItems: {},
    autoComboPrpIds: [],
    comboPrpIdToALaCartePrpId: {},
    brandMenu,
  };
  return processedMenu;
};
